import {
    db
} from '../main'
import firebase from 'firebase/app'
import unitplantemplate from '../data/unitplantemplate.json'
import weeklyplantemplate from '../data/weeklyplantemplate.json'
import dailyplantemplate from '../data/dailyplantemplate.json';
var isTraversable = o => Array.isArray(o) || o !== null && typeof o === 'object';
var toType = function(obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
  }
export function createUnitPlan(uid) {
    //deep copy template https://stackoverflow.com/questions/20662319/javascript-deep-copy-using-json
    var up = JSON.parse(JSON.stringify(unitplantemplate));
    up.uid = uid;
    up.name = "New Unit Plan";
    up.created = firebase.firestore.FieldValue.serverTimestamp();
    return db.collection('unitPlans').add(up)
        .then(function (docRef) {
            return docRef;
        })
        .catch(function (error) {
            alert("Error adding document: ", error);
        });
}

export function createWeeklyPlan(unit_plan_id) {
    var template = JSON.parse(JSON.stringify(weeklyplantemplate));
    return db.collection('unitPlans').doc(unit_plan_id).collection('/weeklyPlans').add(template)
        .then(function (docRef) {
            return docRef;
        })
        .catch(function (error) {
            alert("Error adding document: ", error);
        });
}

export function updateUnitPlan(unitPlan){
    var unit_template = JSON.parse(JSON.stringify(unitplantemplate))
    var weekly_template = JSON.parse(JSON.stringify(weeklyplantemplate));
    var daily_template = JSON.parse(JSON.stringify(dailyplantemplate));
    console.log("Unit Plan")
    check_schema(unitPlan, unit_template)
    for (let i = 0; i <  unitPlan.dailyPlans.length; i++)
    {
        console.log("daily plan #"+i)
        check_schema(unitPlan.dailyPlans[i], daily_template)
    }
    for (let i = 0; i <  unitPlan.weeklyPlans.length; i++)
    {
        console.log("weekly plan #"+i)
        check_schema(unitPlan.weeklyPlans[i], weekly_template)
    }

}

function check_schema(element, schema)
{
    //console.log("check schema --------: " + element)
    for(let [key, value] of Object.entries(schema))
    {
        //console.log("schema key " +key+", value: "+ value)
        if(key in element)
        {
            //console.log("element key " +key+", value: "+ element[key])
            if(toType(element[key]) == toType(schema[key]))
            {
                //console.log("Type match")
                //If the key exists and matches, keep traversing
                // Otherwise there's no point.
                if(isTraversable(schema[key]))
                {
                    check_schema(element[key], schema[key])
                }
                else {
                    //console.log("reached leaf, not traversible: "+ key)
                }
            }
            else {
                console.log("xxxxxx Type does not match: "+ key)
                if (key === "attachments")
                {
                    element[key] = value
                }
            }            
        } else 
        {
            console.log("element key not found: "+ key + "Template")
            console.log(value)
            element[key] = value
        }        
    }
}