var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container", attrs: { id: "app" } },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "lg" } },
        [
          _c("b-navbar-brand", { attrs: { href: "/" } }, [
            _vm._v("Unit Planner")
          ]),
          _c("b-navbar-toggle", { attrs: { target: "nav_collapse" } }),
          _c(
            "b-collapse",
            { attrs: { "is-nav": "", id: "nav_collapse" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "ml-auto" },
                [
                  _c(
                    "b-nav-item-dropdown",
                    { attrs: { text: "stuff", right: "" } },
                    [
                      _c("b-dropdown-item", { attrs: { href: "#" } }, [
                        _vm._v("EN")
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "#" } }, [
                        _vm._v("ES")
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "#" } }, [
                        _vm._v("RU")
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "#" } }, [
                        _vm._v("FA")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-nav-item-dropdown",
                    { attrs: { right: "" } },
                    [
                      _c("template", { slot: "button-content" }, [
                        _c("em", [_vm._v("User")])
                      ]),
                      _c("b-dropdown-item", { attrs: { href: "#" } }, [
                        _vm._v("Profile")
                      ]),
                      _c("b-dropdown-item", { attrs: { to: "logout" } }, [
                        _vm._v("Logout")
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }