<template>
<input
          type="text"
          v-model="field_data"
          placeholder="Enter a title"/>
</template>
<script>
import { UpVuexComponentMixin } from "@/mixins/UpVuexComponentMixin"
import _ from "lodash";

export default {
  props: ['value'],
  mixins: [UpVuexComponentMixin],
  data () {
    return {
      field_data: this.value
    }
  },
};
</script>

<style scoped>

</style>