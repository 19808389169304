<template>
  <div class="row justify-content-center">
    <div class="col-md-12 col-lg-10 border border-secondary p-5">
      <p><span v-if="saving>0">saving...</span>
        <span v-else>saved</span>
      </p>
      <!-- <md-button class="md-primary md-raised" @click="print">Print</md-button>

        <md-button v-on:click="exportUnitPlan">Export JSON</md-button>
        <md-button class="md-accent" v-on:click="showDeleteUnitPlan=true">Delete Unit Plan</md-button>
        <md-dialog-confirm
          :md-active.sync="showDeleteUnitPlan"
          md-title="Delete Unit Plan?"
          :md-content="'This will delete the unit plan '+unitPlan.name+'. Are you sure you want to continue?'"
          md-confirm-text="Delete"
          md-cancel-text="Cancel"
          @md-confirm="deleteUnitPlan" />
        <md-button class="md-accent" v-on:click="updateSchema">Update UnitPlan</md-button> -->
      <div class="d-flex">
        <unit-plan-text-field
          class="h1 header-input mr-auto flex-grow-1"
          v-model="unitPlan.title"
          placeholder="Enter a title"
          path="title"
          :db_id="unitPlan.id"
          v-on:updateStore="updateStore"
        />
      </div>
      <label class="label">Start Date 
      <unit-plan-date-picker v-model="unitPlan.start_date" path="start_date"
          :db_id="unitPlan.id"
          v-on:updateStore="updateStore"/></label>
      <div
        v-for="section in sortAndReturnArray(unitPlan.sections)" :key="section.id"
      >
        <h2 class="my-4">{{ section.title }}</h2>
        <div class="pl-5">
          <component
            v-for="field in sortAndReturnArray(section.fields)"
            :key="field.id"
            :is="dynamicFieldComponent(field.type)"
            :field="field"
            :path='"sections."+section.id+".fields."+field.id+".data"'
            vuexModule="unitPlans"
            :db_id="unitPlan.id"
            v-model="field.data"
            v-on:updateStore="updateStore"
          ></component>
        </div>
        <hr>
      </div>

        <b-tabs card>
        <!-- <weekly-plan :subunitPlan="selectedWeeklyPlan" :dailyPlans="null" ></weekly-plan> 
        <md-card-header class="md-display-3">Weekly Plan</md-card-header>
        <md-tabs md-sync-route>
            <md-tab v-for="(subunitPlan, idx) in unitPlan.subunitPlans" 
            :key="idx" 
            :id="idx.toString()" 
            :md-label="getWeekTabText(idx)"
            :to="getWeekRoute(idx)">
              
              <!-- Week component -->

             <!--<weekly-plan :unitPlan=unitPlan :weekNum=idx :subunitPlan.sync=subunitPlan :dailyPlans="dailyPlanSlice(idx)"></weekly-plan>
            </md-tab>
            <md-tab id="add-tab" md-label="+new" v-on:click="createWeeklyPlanClicked"></md-tab>
        </md-tabs> -->
        <b-tab v-for="subunitPlan in subunitPlans" :key="'dyn-tab-' + subunitPlan.id" :title="'Tab '">

          <subunit-plan :subunitPlan="subunitPlan" :unitPlan="unitPlan" 
          :path="`subunits.${subunitPlan.id}`" v-on:updateStore="updateStore"></subunit-plan>
        </b-tab>

        <!-- New Tab Button (Using tabs slot) -->
        <template slot="tabs">
          <b-nav-item @click.prevent="createSubunit" href="#"><b>+</b><span class="sr-only">Create a new subunit</span></b-nav-item>
        </template>

        <!-- Render this if no tabs -->
        <div slot="empty" class="text-center text-muted">
          There are no open tabs<br>
          Open a new tab using the <b>+</b> button above.
        </div>
        </b-tabs>
    </div>
  </div>
</template>

<script>
//const version=2
export const FIELDTYPES = {
  text: "UnitPlanMarkdownTextField",
  text_array: "TextArray",
  standards_select: "UnitPlanStandardsSelectField",
};
import _ from "lodash";
import UnitPlanMarkdownTextField from "../components/UnitPlanMarkdownTextField";
import TextArray from "../components/TextArray";
import SubunitPlan from "../views/SubunitPlan";
import { updateUnitPlan } from '../models/UnitPlan'
import UnitPlanStandardsSelectField from "../components/UnitPlanStandardsSelectFieldAlt1";
import UnitPlanTextField from "../components/UnitPlanTextField";
import UnitPlanDatePicker from "../components/UnitPlanDatePicker";
import uuid from "uuid/v1"
import schemas from 'unit-planner-functions/Schemas'
// import { unitPlanSchemaExample } from '@/models/SchemaExample'
// import moment from 'moment'
export default {
  components: {
    UnitPlanMarkdownTextField,
    UnitPlanTextField,
    UnitPlanDatePicker,
    UnitPlanStandardsSelectField,
    SubunitPlan,
    TextArray,
  },
  data () {
    return {
      start_date: null,
      saving: 0,
      showDeleteUnitPlan: false,
      showDeleteWeek: false,
      showPrint: false,
      tabs: [],
        tabCounter: 0
    };
  },

  computed: {
    unitPlan () {
      console.log(_.get(this.$store.state.unitPlans.data, this.$route.params.unitPlanId, { id: "null" }))
      return _.get(this.$store.state.unitPlans.data, this.$route.params.unitPlanId, { id: "null" })    },
    subunitPlans() {
      return this.sortAndReturnArray(this.unitPlan.subunits)
      // _.get(this.unitPlan.subunitPlans, [{ id: "null" }])
      /*
      TODO: 
      set up the weekly plan infrastucture as normal. Create new one, etc etc.

      Then, using similar logic to the sortAndReturnArray(obj),
      return an array of subunitPlans that can be passed to the weekly plan component.


      */
    }
  },
  methods: {

    dynamicFieldComponent (type) {
      return FIELDTYPES[type];
    },

    createSubunit: function () { 
      //Simply create a new key in subunits.
      let myUuid = uuid()
      let patch = {subunits: {[myUuid]:  schemas.subunitSchema()}}
      this.updateStore(patch)
    },
    deleteUnitPlan: function () {
      console.log("delete unit plan")
    },
    print: function () {
      window.open('/#/unit-plan/' + this.unitPlan.id + '/print', '_blank')
    },
    updateSchema: function () {
      console.log("updating...")
      //Attempt to update the unit plan schema. Potentially very dangerous!
      updateUnitPlan(this.unitPlan)
    },
    updateStore(patch) {
            //this.$store.dispatch('courses/patch', {id: this.courseId, events: value}) }
            console.log("Unit Plan update store")
            
            //ADD THE DB ID TO THE PATCH
            patch.id = this.unitPlan.id
            console.log(patch)
            this.saving++
            this.$store.dispatch(`unitPlans/patch`, patch).then(()=>{
              this.saving--
            })
        },
    /*
    This sorts by the "order" key in whatever object this is passed, and returns an array sorted by the order.
    The idea is if you have an object with a bunch of keys, (like in Firestore), but need to enforce an order,
    this will return the objects ordered by the key.
    */
    sortAndReturnArray(obj){
      if(!obj)
      {
        return null
      }
      return _.sortBy(_.map(obj, (value, key) => {
        value["id"] = key
        return value
      }), 'order')
    }
  },
  beforeRouteUpdate (to, from, next) {
    // // react to route changes...
    // if (from.params.id != to.params.id) {
    //   if(this.saving==true){ // Here we check if saving. If so, call the before route update again
    //     next(false)
    //     return
    //   }
    //   else{
    //     this.$unbind("unitPlan")
    //     this.unitPlan = {dailyPlans: [], subunitPlans: []}
    //     this.$bind("unitPlan", db.collection("unitPlans").doc(to.params.id));
    //   }
    // }
    next()
  },
  watch: {
    // unitPlan: {
    //   handler: function(newUnitPlan) {
    //     //this.$store.dispatch('courses/{unitPlan/set', {id, events: {[event.id]: event}})
    //     // if (newUnitPlan.start_date == null){
    //     //   this.start_date = null
    //     // }
    //     // else {
    //     //   var newDate = newUnitPlan.start_date.toDate()
    //     //   if (this.start_date == null)
    //     //     this.start_date = newDate
    //     //   else if (newDate.getTime() != this.start_date.getTime())
    //     //   {
    //     //     console.log("Dates different, setting local date")
    //     //     this.start_date = newUnitPlan.start_date.toDate()
    //     //   }
    //     //   else{
    //     //     console.log(newUnitPlan.start_date)
    //     //     console.log("Dates were the same")
    //     //   }
    //     // }
    //     // this.saving = true;
    //     // console.log("saving");
    //     // this.save();
    //   },
    //   deep: true
    // // },
    // // start_date: {
    // //   handler: function(newDate) {
    // //     if (newDate == null)
    // //       {
    // //         this.unitPlan.start_date = null
    // //         console.log("null date")
    // //       }
    // //     else
    // //       this.unitPlan.start_date = firebase.firestore.Timestamp.fromDate(newDate)
    // //   }
    // }
  }
};
</script>

<style lang="scss">
@import "~vue-material/dist/vue-material.min.css"; // deprecate ASAP
@import "~vue-material/dist/theme/default.css"; // deprecate ASAP
@import "../styles/global.css"; // deprecate ASAP
.header-input {
  &:hover {
    box-shadow: 0 0 3pt 2pt darkgray;
  }
  border: none;
}
.clickable {
  &:hover {
    box-shadow: 0 0 3pt 2pt darkgray;
  }
  padding: 10px;
}
</style>