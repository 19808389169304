<template>
  <div>
    <md-field>
      <md-select
        v-model="field_data"
        name="week_targets"
        id="week_targets"
        multiple
      >
        <md-option
          v-for="(item, idx) in source"
          :value=item
          :key="idx"
        >{{ item }}</md-option>
      </md-select>
    </md-field>
    <ul>
      <li
        v-for="(item, idx) in field_data"
        :key="idx"
      >
        {{item}}
      </li>
    </ul>
  </div>
</template>
<script>
import { UpVuexComponentMixin } from "@/mixins/UpVuexComponentMixin"
import _ from "lodash";

export default {
  props: ['value', 'source'],
  mixins: [UpVuexComponentMixin],
  data () {
    return {
      field_data: this.value
    }
  },
};
</script>

<style scoped>
</style>