<template>
    <div>
        
        <label>{{field.label}}</label>


        <div v-if="editing" class="md-layout">
            <div class="md-layout md-layout-item md-size-50">
                    <md-field style="width:100%" class="md-layout-item">
                        <textarea v-model="field_data" :md-autogrow="true"></textarea>
                    </md-field>
                
                <div>
                
            
            </div>
            </div>
            
        <div class="editor md-layout-item md-size-50 md-small-hide md-xsmall-hide" v-html="compiledMarkdown" @click="editing=true"></div>
<md-button @click="editing=false">done</md-button>
            <md-button @click="help=!help">help</md-button>
            <div v-if="help" class="md-layout-item md-size-20" >
                    <p>Accepts <a href="https://www.markdownguide.org/cheat-sheet/">markdown</a> formatting</p>
                    <p>Additional formatting:</p>
                    <md-table>
                        <md-table-row>
                            <md-table-head>input</md-table-head>
                            <md-table-head>output</md-table-head>
                        </md-table-row>
                        <md-table-row v-for="(value, key) in filter_replacements" :key="key">
                            <md-table-cell>{{key}}</md-table-cell>
                            <md-table-cell v-html="value"></md-table-cell>
                        </md-table-row>
                    </md-table>
                </div>
        </div>
        
        <div class="clickable" :tabindex="0" v-else v-html="compiledMarkdown" @click="editing=true">
        </div>
        <span class="clickable" v-if="!field_data" @click="editing=true" :tabindex="0">click to edit</span>
    </div>
</template>

<script>
import { filterText, filter_replacements } from "../models/TextFilter";
import { UpVuexComponentMixin } from "@/mixins/UpVuexComponentMixin"
import _ from "lodash";
export default {
  props: ["field", "path", "value"],
  mixins: [UpVuexComponentMixin],
  data() {
    return {
      editing: false,
      filter_replacements: filter_replacements,
      compiledMarkdown: filterText(this.value),
      help: false,
      field_data: this.value || ""
    };
  },
  computed: {
  },
  watch: {
    "field_data": 
      _.debounce(function(newValue) {
        this.compiledMarkdown = filterText(newValue);
      }, 500)      
  }
};
</script>

<style scoped>

.editor{
    padding: 20px;
    border-left-width: 5px;
    border-left-color: #333
}

textarea{
    width: 100%;
    min-height: 200px;
    font-size: 11pt;
    border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: 'Monaco', courier, monospace;
  padding: 20px;
}
.clickable {
  cursor: pointer;
}
</style>