<template>
<div>
  hi
</div>
</template>

<script>
// @ is an alias to /src
import { db } from '../main'
export default {
  name: 'home',
  data() {
    return {
      standards: []
    }
  },
  firestore() {
    return {
      standards: db.collection('standards')
    }
  }
}
</script>
<style lang="css" scoped>
  .md-card {
    max-width: 600px;
    margin: 4px;
    display: block;
    vertical-align: top;
  }
</style>