var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h3", [_vm._v("\n    " + _vm._s(_vm.field.label) + "\n  ")]),
    _c(
      "ul",
      [
        _vm._l(_vm.field_data, function(item, idx) {
          return _c(
            "li",
            { key: idx },
            [
              _c(
                "md-button",
                {
                  staticClass: "md-icon-button md-body-1 remove",
                  attrs: { "md-ripple": false, "aria-label": "remove item" },
                  on: {
                    click: function($event) {
                      return _vm.deleteItem(idx)
                    }
                  }
                },
                [_vm._v("\n        X\n      ")]
              ),
              _c("span", { staticClass: "align-span" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.field_data[idx],
                      expression: "field_data[idx]"
                    }
                  ],
                  domProps: { value: _vm.field_data[idx] },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.field_data, idx, $event.target.value)
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "clear" })
            ],
            1
          )
        }),
        _c("li", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newItem,
                expression: "newItem"
              }
            ],
            staticClass: "new-item",
            domProps: { value: _vm.newItem },
            on: {
              keyup: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.addItem($event)
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.newItem = $event.target.value
              }
            }
          })
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }