<template>
  <div class="page-container" id="app">
    <router-view/>
  </div>
</template>
<script>
import UnitPlanNavigation from './components/UnitPlanNavigation'



export default {
	components: {
		UnitPlanNavigation
	},
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    }
  },
}
</script>
<style lang="css" scoped>

.md-drawer {
    width: 230px;
    max-width: calc(100vw - 125px);
  }


</style>