var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n      " + _vm._s(_vm.field.label) + "\n      ")]),
      _c(
        "md-field",
        [
          _c(
            "md-select",
            {
              attrs: { name: "standards", id: "standards", multiple: "" },
              model: {
                value: _vm.field_data,
                callback: function($$v) {
                  _vm.field_data = $$v
                },
                expression: "field_data"
              }
            },
            _vm._l(_vm.myStandards, function(standard) {
              return _c(
                "md-option",
                {
                  key: standard.id,
                  attrs: { value: standard.benchmark_number }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(standard.benchmark_number) +
                      "\n          "
                  ),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        "white-space": "normal",
                        "max-height": "5em",
                        overflow: "hidden"
                      }
                    },
                    [_vm._v(_vm._s(standard.description))]
                  )
                ]
              )
            }),
            1
          ),
          _c("label", { attrs: { for: "standards" } }, [
            _vm._v("Select Standards")
          ])
        ],
        1
      ),
      _c(
        "md-button",
        {
          on: {
            click: function($event) {
              _vm.isCollapsed = !_vm.isCollapsed
            }
          }
        },
        [_vm._v(_vm._s(_vm.isCollapsed ? "Expand" : "Collapse"))]
      ),
      _c(
        "ul",
        { staticClass: "standards-list" },
        _vm._l(_vm.field_data, function(standard) {
          return _c(
            "li",
            {
              key: standard.benchmark_number,
              class: ["standards-list-item", { collapsed: _vm.isCollapsed }]
            },
            [
              _c("p", { staticClass: "md-title" }, [
                _vm._v(
                  _vm._s(_vm.my_standards_dict.get(standard).benchmark_number)
                )
              ]),
              _c("span", { staticClass: "md-subheading" }, [
                _vm._v(
                  _vm._s(_vm.my_standards_dict.get(standard).idea_standard)
                )
              ]),
              _c("p", { class: { collapsed: _vm.isCollapsed } }, [
                _vm._v(
                  " " + _vm._s(_vm.my_standards_dict.get(standard).description)
                )
              ])
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }