var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", [_vm._v(_vm._s(_vm.field.label))]),
    _vm.editing
      ? _c(
          "div",
          { staticClass: "md-layout" },
          [
            _c(
              "div",
              { staticClass: "md-layout md-layout-item md-size-50" },
              [
                _c(
                  "md-field",
                  {
                    staticClass: "md-layout-item",
                    staticStyle: { width: "100%" }
                  },
                  [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.field_data,
                          expression: "field_data"
                        }
                      ],
                      attrs: { "md-autogrow": true },
                      domProps: { value: _vm.field_data },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.field_data = $event.target.value
                        }
                      }
                    })
                  ]
                ),
                _c("div")
              ],
              1
            ),
            _c("div", {
              staticClass:
                "editor md-layout-item md-size-50 md-small-hide md-xsmall-hide",
              domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) },
              on: {
                click: function($event) {
                  _vm.editing = true
                }
              }
            }),
            _c(
              "md-button",
              {
                on: {
                  click: function($event) {
                    _vm.editing = false
                  }
                }
              },
              [_vm._v("done")]
            ),
            _c(
              "md-button",
              {
                on: {
                  click: function($event) {
                    _vm.help = !_vm.help
                  }
                }
              },
              [_vm._v("help")]
            ),
            _vm.help
              ? _c(
                  "div",
                  { staticClass: "md-layout-item md-size-20" },
                  [
                    _vm._m(0),
                    _c("p", [_vm._v("Additional formatting:")]),
                    _c(
                      "md-table",
                      [
                        _c(
                          "md-table-row",
                          [
                            _c("md-table-head", [_vm._v("input")]),
                            _c("md-table-head", [_vm._v("output")])
                          ],
                          1
                        ),
                        _vm._l(_vm.filter_replacements, function(value, key) {
                          return _c(
                            "md-table-row",
                            { key: key },
                            [
                              _c("md-table-cell", [_vm._v(_vm._s(key))]),
                              _c("md-table-cell", {
                                domProps: { innerHTML: _vm._s(value) }
                              })
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              : _vm._e()
          ],
          1
        )
      : _c("div", {
          staticClass: "clickable",
          attrs: { tabindex: 0 },
          domProps: { innerHTML: _vm._s(_vm.compiledMarkdown) },
          on: {
            click: function($event) {
              _vm.editing = true
            }
          }
        }),
    !_vm.field_data
      ? _c(
          "span",
          {
            staticClass: "clickable",
            attrs: { tabindex: 0 },
            on: {
              click: function($event) {
                _vm.editing = true
              }
            }
          },
          [_vm._v("click to edit")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("Accepts "),
      _c(
        "a",
        { attrs: { href: "https://www.markdownguide.org/cheat-sheet/" } },
        [_vm._v("markdown")]
      ),
      _vm._v(" formatting")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }