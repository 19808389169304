import firebase from 'firebase/app'
import 'firebase/functions'

import config from '@/config'

const firebase_env = process.env.VUE_APP_FIREBASE_ENV
firebase.initializeApp(config[firebase_env].firebase)
const functions = firebase.functions()
//functions.useFunctionsEmulator("http://localhost:5001") 
if (process.env.VUE_APP_FIREBASE_API) {
  functions.useFunctionsEmulator(process.env.VUE_APP_FIREBASE_API) 
}

export var createCourse = functions.httpsCallable('createCourse')
export var createUnitPlan = functions.httpsCallable('createUnitPlan')