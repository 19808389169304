import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Authenticated from './views/Authenticated.vue'
import About from './views/About.vue'
import Welcome from './views/Welcome.vue'
// import StandardsList from './views/StandardsList.vue'
// import Auth from './components/Auth.vue';
// import AuthSuccess from './components/AuthSuccess.vue';
import Dashboard from './views/Dashboard.vue';
import UnitPlan from './views/UnitPlan.vue';
import CourseDashboard from './views/CourseDashboard.vue';
import Course from './views/Course.vue';
import WeeklyPlan from './views/WeeklyPlan.vue';
import UnitPlanPrintView from './views/UnitPlanPrintView.vue';
import store from './store/index'
//import gapi_util from './gapi_util'
Vue.use(Router)

/* global gapi */ // This is injected from an external script. This line mutes ESLint


// authentication guard
const authRequired = (to, from, next) => {
	if (!store.state.auth.authenticated) {
		next({path: '/welcome'})
	} else {
		next()
	}
}

export default new Router({
  routes: [
    // {
    //   name: 'login',
    //   path: '/login', 
    //   component: Home,
    //   beforeEnter: (to, from, next) => {
    //     if (store.state.auth.authenticated) {
    //       next()
    //     } else {
    //       var auth = gapi.auth2.getAuthInstance()
    //       auth.signIn().then((e) => {
    //         console.log('SignIn');
    //         gapi_util.updateAuthStatus().then(() => {
    //           next({name: 'home'})
    //         })
    //       })
    //     }
    //   }
    // },
    {
      name: 'logout',
      path: '/logout',
      component: Home,
      beforeEnter: () => {
        var auth = gapi.auth2.getAuthInstance()
        auth.signOut().then(() => {
          document.location = '/'
        })
      }
    },
    {
      path: '/',
      component: Authenticated,
      beforeEnter: authRequired,
      children: [ // AUTHENTICATED ROUTES
        {
          path: '',
          name: 'dashboard',
          component: Dashboard
        },
        {
          path: 'course/:courseId',
          component: Course,
          /* This actually needs to be the "course" parent with a side column layout
          make the course dashboard and the unit plan itself children, so that the side navigation can be shared.
          Make the side navigation hideable, possibly by having the open/closed state stored in vuex.
          */
         children: [
          {
            path: '',
            name:'course',
            component: CourseDashboard,
          },
          {
            path: 'unit-plan/:unitPlanId',
            name:'unit-plan',
            component: UnitPlan,
            children: [
              {
                path: 'week/:weeknum',
                name: 'unit-plan-week',
                component: WeeklyPlan
              },
              {
                path: 'print',
                name:'unit-plan-print',
                component: UnitPlanPrintView
              },
            ]
          },  
        ]
        },
        
      ]
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    { // Log in page
      path: '/welcome',
      name: 'welcome',
      component: Welcome,
      beforeEnter: (to, from, next) => {
        if (store.state.auth.authenticated) {
          next({name: 'dashboard'})
        } else {
          next()
        }
      }
    },
   
    // { path: '/auth', component: Auth },
    // { path: '/success', component: AuthSuccess }
  ]
})