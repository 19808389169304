<template>
  <div>
    <md-button v-on:click="exportWeek">Export Week</md-button>
    <md-button class="md-accent" v-on:click="showDeleteWeek=true">Delete Week</md-button>
    <md-dialog-confirm :md-active.sync="showDeleteWeek" md-title="Delete Week?" :md-content="'This will delete this week '+weekNum+'. Are you sure you want to continue?'" md-confirm-text="Delete" md-cancel-text="Cancel" @md-confirm="deleteWeek" />
    <md-card-header class="md-title">Focus Standards</md-card-header>
    <md-field>
    <md-select v-model="myWeeklyPlan.sections[0].fields[0].data" name="week_standards" id="week_standards" multiple>
      <md-option v-for="(item, idx) in unitPlan.sections[0].fields[0].data" :value=item :key="idx">{{ item }}</md-option>
    </md-select>
    </md-field>
    <ul>
    <li v-for="(item, idx) in myWeeklyPlan.sections[0].fields[0].data" :key="idx">
      {{item}}
    </li>
    </ul>
    
    <md-card-header class="md-title">
      Learning Targets
    </md-card-header>
    <md-field>
    <md-select v-model="myWeeklyPlan.sections[1].fields[0].data" name="week_targets" id="week_targets" multiple>
      <md-option v-for="(item, idx) in unitPlan.sections[0].fields[1].data" :value=item :key="idx">{{ item }}</md-option>
    </md-select>
    </md-field>
    <ul>
    <li v-for="(item, idx) in myWeeklyPlan.sections[1].fields[0].data" :key="idx">
      {{item}}
    </li>
    </ul>
    <!-- <div class="md-layout md-gutter md-alignment-center"> -->
      <div class="md-layout" v-for="(dailyPlan, idx3) in dailyPlans" :key="'daily'+idx3">
        <div class="md-layout-item md-layout md-xlarge-size-15 md-large-size-20 md-medium-size-100">
          <h2>
          <div class="md-layout-item md-large-size-100 md-medium-size-50">
            <span class="md-title">{{ dayDate(idx3+1).format('dddd') }}</span>
          </div>
          <div class="md-layout-item md-large-size-100 md-medium-size-50">
            <span class="md-subheading">{{ dayDate(idx3+1).format('MM/DD') }}</span>
          </div> 
          </h2>
        </div>      
        <div class="md-layout-item md-xlarge-size-60 md-large-size-50 md-medium-size-100">
          <component v-for="(item, idx4) in dailyPlan.fields" :key="idx4" :is="dynamicFieldComponent(item.type)" :field="item"></component>
        </div>
        <div class="md-layout-item md-xlarge-size-25 md-large-size-30 md-medium-size-100">
          <text-array :field='{"label": "Attachments"}' v-model="dailyPlan.attachments.data"></text-array>
        </div>
        <div v-if="idx3 != dailyPlans.length-1" class="md-layout-item md-size-100">
          <hr>
        </div>
      </div>
      
        <div class="spacer"></div>

    <!-- </div> -->

  </div>
</template>

<script>
// import { db } from "../main";
import UnitPlanMarkdownTextField from "../components/UnitPlanMarkdownTextField";
import TextArray from "../components/TextArray";
import { FIELDTYPES } from "./UnitPlan"
import moment from 'moment'
export default {
  props: ["unitPlan", "weeklyPlan", "dailyPlans", "weekNum"],
  data () {
    return {
      // focusStandards: [],
      // learningTargets: [],
      myWeeklyPlan: this.weeklyPlan,
      showDeleteWeek: false
    }
  },
  components: {
    UnitPlanMarkdownTextField,
    TextArray
  },
  methods: {
    dynamicFieldComponent (type) {
      return FIELDTYPES[type];
    },
    dayOfWeek (int) {
      return moment().day(int).format("dddd")
    },
    dayDate: function (weekDay) {

      var dayDate = moment(this.unitPlan.start_date.toDate())
      dayDate.add({ days: weekDay - 1, weeks: this.weekNum })
      return dayDate
    },
    formatDayDate: function (weekDay) {
      return this.dayDate(weekDay).format("dddd | M/D/YY")
    },

    exportWeek: function () {
    },
    deleteWeek: function () {
      console.log("delete week")
      var days_in_week = 5 //TODO: no magic numbers
      this.unitPlan.weeklyPlans.splice(this.weekNum, 1)
      this.unitPlan.dailyPlans.splice(this.weekNum * days_in_week, days_in_week)

    },
  },
  computed: {

  },
  watch: {
    weeklyPlan: function (newWeeklyPlan) {
      this.myWeeklyPlan = newWeeklyPlan
    },
    myWeeklyPlan: function (newWeeklyPlan) {
      this.$emit('update:weeklyPlan', newWeeklyPlan)
    },
  }
};
</script>

<style scoped>
.separator {
  width: 100%;
  border-width: 1px;
  height: 12px;
  border-color: #111111;
  display:block;
}
.spacer {
  height:100px;
}
.md-menu-content {
  width: 600px;
}
</style>