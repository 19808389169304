var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("create-unit-plan-modal", {
        ref: "createUnitPlanModal",
        attrs: { id: "myModal", courseId: _vm.courseId }
      }),
      _c(
        "h3",
        [
          _c(
            "router-link",
            {
              attrs: {
                to: { name: "course", params: { courseId: _vm.courseId } }
              }
            },
            [_vm._v(_vm._s(_vm.courseName))]
          )
        ],
        1
      ),
      _c(
        "b-button",
        {
          staticClass: "align-self-baseline",
          on: {
            click: function($event) {
              return _vm.$refs.createUnitPlanModal.show()
            }
          }
        },
        [_vm._v("New Unit Plan")]
      ),
      _c(
        "ul",
        _vm._l(_vm.unitPlans, function(unitPlan, idx) {
          return _c(
            "li",
            { key: idx },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "unit-plan",
                      params: {
                        unitPlanId: unitPlan.id,
                        courseId: _vm.courseId
                      }
                    }
                  }
                },
                [
                  _vm._v(
                    _vm._s(unitPlan.title) +
                      " (" +
                      _vm._s(_vm.formatDate(unitPlan.start_date)) +
                      ")"
                  )
                ]
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }