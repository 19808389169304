var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("datepicker", {
    model: {
      value: _vm.field_data,
      callback: function($$v) {
        _vm.field_data = $$v
      },
      expression: "field_data"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }