
import marked from 'marked'

export const filter_replacements = {
    ":blue:" : '<i aria-label="blue bookmark" style="color:blue" class="fas fa-bookmark"></i>', 
    ":purple:" : '<i aria-label="purple bookmark" style="color:purple" class="fas fa-bookmark"></i>', 
    ":green:" : '<i aria-label="green bookmark" style="color:green" class="fas fa-bookmark"></i>', 
    ":yellow:" : '<i aria-label="orange bookmark" style="color:orange" class="fas fa-bookmark"></i>',
    ":red:" : '<i aria-label="red bookmark" style="color:red" class="fas fa-bookmark"></i>',
    ":pink:" : '<i aria-label="red bookmark" style="color:hotpink" class="fas fa-bookmark"></i>',
    ":gray:" : '<i aria-label="red bookmark" style="color:gray" class="fas fa-bookmark"></i>'

}

export function filterText(input){
    var output = marked(input, { sanitize: true })
    // Dynamically create a regex object that will match any of the keys in filter_replacements
    var re = new RegExp(Object.keys(filter_replacements).join("|"),"g");
    output = output.replace(re, function(matched){
    return filter_replacements[matched];
    });
    return output
}