<template>
<datepicker v-model="field_data">
</datepicker>
</template>
<script>
import { UpVuexComponentMixin } from "@/mixins/UpVuexComponentMixin"
import Datepicker from 'vuejs-datepicker';

export default {
  props: ['value'],
  components: {
    Datepicker
  },
  mixins: [UpVuexComponentMixin],
  data () {
    return {
      field_data: this.value
    }
  },
};
</script>

<style scoped>

</style>