import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueFire from 'vuefire'
// import firebaseui from 'firebaseui'
import firebase from 'firebase/app'
import 'firebase/firestore'

import VueMaterial from 'vue-material';// deprecate ASAP
//import 'vue-material/dist/vue-material.min.css'// deprecate ASAP
//import 'vue-material/dist/theme/default.css'// deprecate ASAP
//import './styles/global.css'// deprecate ASAP
import '@fortawesome/fontawesome-free/css/all.min.css'
import standards_import from './data/standards.json'
import store from './store'
import mixins from './mixins'
import gapi_util from './gapi_util'
import Vuetify from 'vuetify'// deprecate ASAP
//import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import './styles/custom.scss' // Imports bootstrap
import BootstrapVue from 'bootstrap-vue'

Vue.mixin(mixins)
Vue.config.productionTip = false
Vue.use(VueFire)
Vue.use(BootstrapVue)

// if dev mode, test with local cloud functions server


// Vue Material : Note, it's possible to import individual components, but not yet. See: https://github.com/vuematerial/vue-material/issues/1182
Vue.use(VueMaterial)
Vue.use(Vuetify)
export const standards = standards_import;
export const standards_dict = new Map(standards.map(standard => {
  return [standard.benchmark_number, standard]
}));
// const settings = {
//   timestampsInSnapshots: true
// };
export const db = firebase.firestore();
export const fFunctions = firebase.functions()
// if (process.env.VUE_APP_FIREBASE_API) {
//   fFunctions.useFunctionsEmulator(process.env.VUE_APP_FIREBASE_API) 
// }
// db.settings(settings)





function init() {
  return new Promise((resolve) => {
    // make sure the google api script is loaded
    gapi_util.initGoogleAPI().then(() => {
      gapi_util.updateAuthStatus().then(() => {
        resolve()
      })
    })
  })
}


init().then(() => {
  new Vue({
    router,
    store,
    created() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user)
          store.dispatch('courses/openDBChannel', {where: [["owner.uid","==",user.uid]]})
      });
    },
    render: h => h(App)
  }).$mount('#app')
})