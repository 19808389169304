var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "calendar-parent" },
      [
        _c(
          "b-modal",
          {
            ref: "addDateModal",
            attrs: { id: "addDateModal", title: "New Event", lazy: "" },
            on: { ok: _vm.handleOkay, cancel: _vm.handleCancel }
          },
          [
            _c("div", { staticClass: "form-group row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c("label", { attrs: { for: "title" } }, [_vm._v("Title:")])
              ]),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedEvent.title,
                      expression: "selectedEvent.title"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "title" },
                  domProps: { value: _vm.selectedEvent.title },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.selectedEvent, "title", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c("label", { attrs: { for: "desc" } }, [
                  _vm._v("Description:")
                ])
              ]),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedEvent.description,
                      expression: "selectedEvent.description"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "desc", rows: "2" },
                  domProps: { value: _vm.selectedEvent.description },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.selectedEvent,
                        "description",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c("label", { attrs: { for: "startDate" } }, [
                  _vm._v("Start Date:")
                ])
              ]),
              _c("div", { staticClass: "col-sm-9" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm._startDate,
                      expression: "_startDate"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "date", id: "startDate" },
                  domProps: { value: _vm._startDate },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm._startDate = $event.target.value
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("div", { staticClass: "col-sm-3" }, [
                _c("label", { attrs: { for: "title" } }, [_vm._v("End Date:")])
              ]),
              _c(
                "div",
                { staticClass: "col-sm-9" },
                [
                  _c("b-form-input", {
                    attrs: { type: "date", id: "endDate" },
                    model: {
                      value: _vm._endDate,
                      callback: function($$v) {
                        _vm._endDate = $$v
                      },
                      expression: "_endDate"
                    }
                  })
                ],
                1
              )
            ])
          ]
        ),
        _c("calendar-view", {
          staticClass:
            "theme-default holiday-us-traditional holiday-us-official",
          attrs: {
            "show-date": _vm.showDate,
            events: _vm.eventArray,
            "enable-drag-drop": true,
            startingDayOfWeek: 1
          },
          on: {
            "drop-on-date": _vm.onDrop,
            "click-date": _vm.onClickDay,
            "click-event": _vm.onClickEvent
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function(t) {
                return _c("calendar-view-header", {
                  attrs: { "header-props": t.headerProps },
                  on: { input: _vm.setShowDate }
                })
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }