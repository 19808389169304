<template>
  <div
    class="page-container"
    id="app"
  >
    <b-navbar
      toggleable="lg"
    >
      <b-navbar-brand href="/">Unit Planner</b-navbar-brand>

      <b-navbar-toggle target="nav_collapse" />

      <b-collapse
        is-nav
        id="nav_collapse"
      >
        

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown
            text="stuff"
            right
          >
            <b-dropdown-item href="#">EN</b-dropdown-item>
            <b-dropdown-item href="#">ES</b-dropdown-item>
            <b-dropdown-item href="#">RU</b-dropdown-item>
            <b-dropdown-item href="#">FA</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item-dropdown right>
            <!-- Using button-content slot -->
            <template slot="button-content"><em>User</em></template>
            <b-dropdown-item href="#">Profile</b-dropdown-item>
            <b-dropdown-item to="logout">Logout</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
  </div>
</template>
<script>



export default {
}
</script>
<style lang="css" scoped>
.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}
</style>