var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.field_data,
        expression: "field_data"
      }
    ],
    attrs: { type: "text", placeholder: "Enter a title" },
    domProps: { value: _vm.field_data },
    on: {
      input: function($event) {
        if ($event.target.composing) {
          return
        }
        _vm.field_data = $event.target.value
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }