var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("\n    Daily Plan\n  ")]),
      _c("unit-plan-markdown-text-field", {
        attrs: {
          field: _vm.dailyPlan.fields["Tasks"],
          path: "fields.Tasks.data"
        },
        on: { updateStore: _vm.updateStore },
        model: {
          value: _vm.dailyPlan.fields["Tasks"].data,
          callback: function($$v) {
            _vm.$set(_vm.dailyPlan.fields["Tasks"], "data", $$v)
          },
          expression: "dailyPlan.fields['Tasks'].data"
        }
      }),
      _c("text-array", {
        attrs: {
          field: _vm.dailyPlan.fields["Attachments"],
          path: "fields.Attachments.data"
        },
        on: { updateStore: _vm.updateStore },
        model: {
          value: _vm.dailyPlan.fields["Attachments"].data,
          callback: function($$v) {
            _vm.$set(_vm.dailyPlan.fields["Attachments"], "data", $$v)
          },
          expression: "dailyPlan.fields['Attachments'].data"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }