var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "b-nav",
          { staticClass: "col-12 col-md-3", attrs: { vertical: "" } },
          [
            _vm._t("left-nav", [
              _c("b-nav-item", { attrs: { active: "" } }, [_vm._v("Active")]),
              _c("b-nav-item", [_vm._v("Link")]),
              _c("b-nav-item", [_vm._v("Another Link")]),
              _c("b-nav-item", { attrs: { disabled: "" } }, [
                _vm._v("Disabled")
              ])
            ])
          ],
          2
        ),
        _c("div", { staticClass: "col-12 col-md-9" }, [
          _c("div", { staticClass: "container" }, [_vm._t("main-content")], 2)
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }