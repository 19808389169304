var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v(_vm._s(_vm.courseName))]),
      _c("img", {
        attrs: {
          src: "https://via.placeholder.com/728x300.png?text=Project+Board",
          alt: "placeholder"
        }
      }),
      _c("h2", [_vm._v(" Calendar ")]),
      _c("unit-plan-calendar", {
        attrs: { events: _vm.courseEvents },
        on: {
          addEvent: _vm.addEvent,
          removeEvent: _vm.removeEvent,
          updateEvent: _vm.updateEvent
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }