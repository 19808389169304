<template>
  <div>
    <h3>
      Daily Plan
    </h3>
    <unit-plan-markdown-text-field
      v-model="dailyPlan.fields['Tasks'].data"
      :field="dailyPlan.fields['Tasks']"
      path="fields.Tasks.data"
      v-on:updateStore="updateStore"
    ></unit-plan-markdown-text-field>
    <text-array
      v-model="dailyPlan.fields['Attachments'].data"
      :field="dailyPlan.fields['Attachments']"
      path="fields.Attachments.data"
      v-on:updateStore="updateStore"
    ></text-array>

  </div>
</template>

<script>
// import { db } from "../main";
import UnitPlanMarkdownTextField from "../components/UnitPlanMarkdownTextField";
import UnitPlanSelectField from "../components/UnitPlanSelectField"
import { UpVuexComponentMixin } from "@/mixins/UpVuexComponentMixin"
import TextArray from "../components/TextArray";
import { FIELDTYPES } from "./UnitPlan"
import moment from 'moment'
export default {
  props: ["dailyPlan"],
  mixins: [UpVuexComponentMixin],
  data () {
    return {
      // focusStandards: [],
      // learningTargets: [],
      showDeleteWeek: false
    }
  },
  components: {
    UnitPlanMarkdownTextField,
    TextArray,
    UnitPlanSelectField
  },
  methods: {
    dynamicFieldComponent (type) {
      return FIELDTYPES[type];
    },
    dayOfWeek (int) {
      return moment().day(int).format("dddd")
    },
    dayDate: function (weekDay) {

      var dayDate = moment(this.unitPlan.start_date.toDate())
      dayDate.add({ days: weekDay - 1, weeks: this.weekNum })
      return dayDate
    },
    formatDayDate: function (weekDay) {
      return this.dayDate(weekDay).format("dddd | M/D/YY")
    },

    exportWeek: function () {
    },
    deleteWeek: function () {
      console.log("delete week")
      var days_in_week = 5 //TODO: no magic numbers
      this.unitPlan.weeklyPlans.splice(this.weekNum, 1)
      this.unitPlan.dailyPlans.splice(this.weekNum * days_in_week, days_in_week)

    },
  },
  computed: {

  },
};
</script>

<style scoped>
.separator {
  width: 100%;
  border-width: 1px;
  height: 12px;
  border-color: #111111;
  display: block;
}
.spacer {
  height: 100px;
}
.md-menu-content {
  width: 600px;
}
</style>