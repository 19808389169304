<template>
  <b-modal
    ref="myModal"
    title="Create a new course"
    hide-footer
    lazy
  >
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="courseNameGroup"
        label="Course Name:"
        label-for="courseNameInput"
        description="Enter a name for your new course."
      >
        <b-form-input
          id="courseNameInput"
          type="text"
          v-model="course.name"
          required
          placeholder="Enter Name"
        />
      </b-form-group>

      <b-form-group
        id="courseStandardGroup"
        label="Standard:"
        label-for="courseStandardInput"
        description="Select a standards set."
      >
        <b-form-select
          id="courseStandardInput"
          :options="standards"
          required
          v-model="course.standard"
        />
      </b-form-group>
      <div class="alert alert-danger" role="alert" id="responseError" v-if="responseError">{{responseError}}</div>
      <div class="d-flex">
        <b-button
          class="mr-auto"
          variant="danger"
          @click="$refs.myModal.hide()"
        >Cancel</b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="busy"
        >
          <b-spinner
            small
            v-if="busy" class="mr-2"
          />
          <span
            class="sr-only"
            v-if="busy"
          >Loading...</span>Create</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import {createCourse} from '@/models/FirebaseFunctions'
export default {
  
  data () {
    return {
      course: {
        name: "",
        standard: ""
      },
      standards: ["opt1", "opt2"],
      responseError: "",
      busy: false
    }
  },
  methods: {
    show () {
      this.$refs.myModal.show()
    },
    onSubmit () {
      this.busy = true;
      createCourse(this.course).then((response) => {
        this.$router.push({ name: 'course', params: { courseId: response.data.docId}})
      }).catch((error) => {
        this.responseError = error.message +": "+ error.details;
        this.busy = false;
      })
    }
  }
}
</script>

<style scoped>
</style>