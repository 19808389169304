<template>
  <div>
    <div class="calendar-parent">
      <b-modal
        id="addDateModal"
        ref="addDateModal"
        title="New Event"
        @ok="handleOkay"
        @cancel="handleCancel"
        lazy
      >
        <div class="form-group row">
          <div class="col-sm-3">
            <label for="title">Title:</label>
          </div>
          <div class="col-sm-9">
            <input
              class="form-control"
              type="text"
              id="title"
              v-model='selectedEvent.title'
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <label for="desc">Description:</label>
          </div>
          <div class="col-sm-9">
            <textarea
              class="form-control"
              type="text"
              id="desc"
              rows="2"
              v-model='selectedEvent.description'
            />
            </div>
        </div>
          <div class="form-group row">
            <div class="col-sm-3">
              <label for="startDate">Start Date:</label>
            </div>
            <div class="col-sm-9">
              <input class="form-control" type="date" id="startDate" v-model='_startDate'/>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-3">
              <label for="title">End Date:</label>
            </div>
            <div class="col-sm-9">
              <b-form-input type="date" id="endDate" v-model='_endDate'/>
            </div>
          </div>
      </b-modal>
      <calendar-view
        :show-date="showDate"
        :events="eventArray"
        :enable-drag-drop="true"
        :startingDayOfWeek="1"
        @drop-on-date="onDrop"
        @click-date="onClickDay"
        @click-event="onClickEvent"
        class="theme-default holiday-us-traditional holiday-us-official"
      >

        <calendar-view-header
          slot="header"
          slot-scope="t"
          :header-props="t.headerProps"
          @input="setShowDate"
        />
      </calendar-view>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import { db } from '../main'
// import { createUnitPlan } from '../models/UnitPlan'
// import CalendarView from "./contrib/vue-simple-calendar/CalendarView.vue"
// import CalendarViewHeader from "./contrib/vue-simple-calendar/CalendarViewHeader.vue"
// import CalendarMathMixin from "./contrib/vue-simple-calendar/CalendarMathMixin.js"
import { CalendarView, CalendarViewHeader, CalendarMathMixin } from 'vue-simple-calendar'
import uuidv4 from 'uuid/v4'
import moment from 'moment'
const eventTemplate = {
  new: false,
  endDate: null,
  startDate: null,
  title: "",
  description: ""
}
export default {
  name: 'UnitPlanCalendar',
  components: {
    CalendarView,
    CalendarViewHeader
  },
  props: { events: { type: Object, default: () => { return {} } } },
  mixins: [CalendarMathMixin],
  data () {
    return {
      showDate: new Date(),
      selectedEvent: Object.assign({}, eventTemplate)
    }
  },
  computed: {
    // This transforms a keyed object into an array of objects with a key property.
    // This is what the vue-calendar requires, but updating the keyed object updates this, so everything is fine.
    eventArray: {
      get () {
        return Object.keys(this.events).map((key) => {
          var rObj = this.events[key]
          rObj.key = key
          return rObj
        })
      }
    },
    _startDate: {
      get () {
        return this.selectedEvent.startDate
        //return (this.selectedEvent.startDate || new Date()).toISOString().split('T')[0];
      },
      set (newVal) {
        this.selectedEvent.startDate = newVal
        //this.selectedEvent.startDate = new Date(newVal)
      }
    },
    _endDate: {
      get () {
        return this.selectedEvent.endDate
        //return (this.selectedEvent.endDate || new Date()).toISOString().split('T')[0];
      },
      set (newVal) {
        this.selectedEvent.endDate = newVal
        //this.selectedEvent.endDate = new Date(newVal)
      }
    }
  },
  methods: {
    handleOkay () {
      if (this.selectedEvent.new) {
        this.selectedEvent.id = uuidv4()
        this.$emit('addEvent', Object.assign({}, this.selectedEvent))
      }
      else {
        this.$emit('updateEvent', Object.assign({}, this.selectedEvent))
      }
      this.selectedEvent = Object.assign({}, eventTemplate)
    },
    handleCancel () {
      this.selectedEvent = Object.assign({}, eventTemplate)
    },
    setShowDate (d) {
      this.showDate = d;
    },
    // periodChanged (range, eventSource) {
    //   // Demo does nothing with this information, just including the method to demonstrate how
    //   // you can listen for changes to the displayed range and react to them (by loading events, etc.)
    //   // console.log(eventSource)
    //   // console.log(range)
    // },
    thisMonth (d, h, m) {
      const t = new Date()
      return new Date(t.getFullYear(), t.getMonth(), d, h || 0, m || 0)
    },
    onClickDay (d) {
      d = moment(d).format("YYYY-MM-DD")
      this.selectedEvent.new = true
      this.selectedEvent.startDate = d
      this.selectedEvent.endDate = d
      this.$refs.addDateModal.show()
    },
    onClickEvent (e) {
      this.selectedEvent = Object.assign({}, this.events[e.id]) // Copy the object so we don't have a weird reactivity issue.
      this.selectedEvent.new = false
      this.$refs.addDateModal.show()
    },

    onDrop (e, date) {
      this.selectedEvent = Object.assign({}, this.events[e.id])
      this.message = `You dropped ${e.id} on ${date.toLocaleDateString()}`
      // Determine the delta between the old start date and the date chosen,
      // and apply that delta to both the start and end date to move the event.
      const eLength = this.dayDiff(e.startDate, date)
      this.selectedEvent.startDate = moment(this.addDays(e.startDate, eLength)).format("YYYY-MM-DD")
      this.selectedEvent.endDate = moment(this.addDays(e.endDate, eLength)).format("YYYY-MM-DD")
      this.$emit('updateEvent', Object.assign({}, this.selectedEvent))
      this.selectedEvent = Object.assign({}, eventTemplate)
    },
  }
}
</script>
<style lang="css" scoped>
.calendar-parent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: hidden;
  min-height: 40rem;
  max-height: 80vh;
  background-color: white;
}
</style>
<style lang="css">
.dow6 {
  display: none;
}
.dow0 {
  display:none;
}
.cv-event.offset1{
  left:20%
}
.cv-event.span1{
  width: calc(20% - .05em)
}
.cv-event.offset2{
  left:40%
}
.cv-event.span2{
  width: calc(40% - .05em)
}
.cv-event.offset3{
  left:60%
}
.cv-event.span3{
  width: calc(60% - .05em)
}
.cv-event.offset4{
  left:80%
}
.cv-event.span4{
  width: calc(80% - .05em)
}
</style>
<!--
<style lang="css">
/*Calendar Header */

.theme-default .cv-header,
.theme-default .cv-header-day {
  background-color: #f0f0f0;
}

.theme-default .cv-header .periodLabel {
  font-size: 1.5em;
}

.theme-default .cv-header button {
  color: #7f7f7f;
}

.theme-default .cv-header button:disabled {
  color: #ccc;
  background-color: #f7f7f7;
}

/* Grid */

.theme-default .cv-day.today {
  background-color: #ffe;
}

.theme-default .cv-day.past {
  background-color: #fafafa;
}

.theme-default .cv-day.outsideOfMonth {
  background-color: #f7f7f7;
}

/* Events */

.theme-default .cv-event {
  border-color: #e0e0f0;
  border-radius: 0.5em;
  background-color: #e7e7ff;
  text-overflow: ellipsis;
}

.theme-default .cv-event.purple {
  background-color: #f0e0ff;
  border-color: #e7d7f7;
}

.theme-default .cv-event.orange {
  background-color: #ffe7d0;
  border-color: #f7e0c7;
}

.theme-default .cv-event.continued::before,
.theme-default .cv-event.toBeContinued::after {
  content: " \21e2 ";
  color: #999;
}

.theme-default .cv-event.toBeContinued {
  border-right-style: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.theme-default .cv-event.isHovered.hasUrl {
  text-decoration: underline;
}

.theme-default .cv-event.continued {
  border-left-style: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Event Times */

.theme-default .cv-event .startTime,
.theme-default .cv-event .endTime {
  font-weight: bold;
  color: #666;
}

/* Drag and drop */

.theme-default .cv-day.draghover {
  box-shadow: inset 0 0 0.2em 0.2em yellow;
}
</style>-->