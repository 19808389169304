var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      ref: "myModal",
      attrs: { title: "Create a new course", "hide-footer": "", lazy: "" }
    },
    [
      _c(
        "b-form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "b-form-group",
            {
              attrs: {
                id: "courseNameGroup",
                label: "Course Name:",
                "label-for": "courseNameInput",
                description: "Enter a name for your new course."
              }
            },
            [
              _c("b-form-input", {
                attrs: {
                  id: "courseNameInput",
                  type: "text",
                  required: "",
                  placeholder: "Enter Name"
                },
                model: {
                  value: _vm.course.name,
                  callback: function($$v) {
                    _vm.$set(_vm.course, "name", $$v)
                  },
                  expression: "course.name"
                }
              })
            ],
            1
          ),
          _c(
            "b-form-group",
            {
              attrs: {
                id: "courseStandardGroup",
                label: "Standard:",
                "label-for": "courseStandardInput",
                description: "Select a standards set."
              }
            },
            [
              _c("b-form-select", {
                attrs: {
                  id: "courseStandardInput",
                  options: _vm.standards,
                  required: ""
                },
                model: {
                  value: _vm.course.standard,
                  callback: function($$v) {
                    _vm.$set(_vm.course, "standard", $$v)
                  },
                  expression: "course.standard"
                }
              })
            ],
            1
          ),
          _vm.responseError
            ? _c(
                "div",
                {
                  staticClass: "alert alert-danger",
                  attrs: { role: "alert", id: "responseError" }
                },
                [_vm._v(_vm._s(_vm.responseError))]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-auto",
                  attrs: { variant: "danger" },
                  on: {
                    click: function($event) {
                      return _vm.$refs.myModal.hide()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "b-button",
                {
                  attrs: {
                    type: "submit",
                    variant: "primary",
                    disabled: _vm.busy
                  }
                },
                [
                  _vm.busy
                    ? _c("b-spinner", {
                        staticClass: "mr-2",
                        attrs: { small: "" }
                      })
                    : _vm._e(),
                  _vm.busy
                    ? _c("span", { staticClass: "sr-only" }, [
                        _vm._v("Loading...")
                      ])
                    : _vm._e(),
                  _vm._v("Create")
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }