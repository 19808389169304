var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "one-column-centered",
    [
      _c(
        "b-jumbotron",
        [
          _c("template", { slot: "header" }, [_vm._v("Unit Planner")]),
          _c("template", { slot: "lead" }, [
            _vm._v(
              "\n      Thank you for using Unit Planner. Please log in to continue.\n    "
            )
          ]),
          _c("hr", { staticClass: "my-4" }),
          _c(
            "b-button",
            {
              staticStyle: { color: "white" },
              attrs: { variant: "primary" },
              on: { click: _vm.login }
            },
            [_vm._v("Log In with Google")]
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }