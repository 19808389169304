import firebase from 'firebase/app'

export default {
	computed: {
		currentUser() {
			return firebase.auth().currentUser
		},
		currentUserProfile() {
			return this.$store.state.auth.currentUserProfile
		},
		authenticated() {
			return this.$store.state.auth.authenticated
		}
	},
	methods: {
		firebaseTimestamp() {
			return firebase.database.ServerValue.TIMESTAMP
		},
		signin() {
            this.$router.push({name: 'login'})
        },
        signout() {
            this.$router.push({name: 'logout'})
        }
	}
}
