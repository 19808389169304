export default {
  "the-unit-planner": {
    firebase: {
      apiKey: "AIzaSyDhr7e9SgJuRN8v5OGq8EUWrGQS3nd9W4Y",
      authDomain: "the-unit-planner.firebaseapp.com",
      databaseURL: "https://the-unit-planner.firebaseio.com",
      projectId: "the-unit-planner",
      storageBucket: "the-unit-planner.appspot.com",
      messagingSenderId: "445466966323"
    },
    google: {
      client_id: '445466966323-o403ovfae52n8knsjlg7inu2ecfhcn6g.apps.googleusercontent.com',
      api_key: 'AIzaSyBNxaeAd9jPn97PwE-151dTXqSMWHlmaSQ',
      hosted_domain: 'unit-planner.com',
      scope: 'profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/plus.login',
      discovery_docs: [
        'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
      ],
    },
  },
  "the-unit-planner-dev": {
    firebase: {
      "apiKey": "AIzaSyCjE12HV4XKRt3TthjS3UZ4_9b0ogMjrng",
      "databaseURL": "https://the-unit-planner-dev.firebaseio.com",
      "storageBucket": "the-unit-planner-dev.appspot.com",
      "authDomain": "the-unit-planner-dev.firebaseapp.com",
      "messagingSenderId": "1032067478555",
      "projectId": "the-unit-planner-dev"
    },
    google: {
      client_id: '445466966323-o403ovfae52n8knsjlg7inu2ecfhcn6g.apps.googleusercontent.com',
      api_key: 'AIzaSyBNxaeAd9jPn97PwE-151dTXqSMWHlmaSQ',
      hosted_domain: 'unit-planner.com',
      scope: 'profile email https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/plus.login',
      discovery_docs: [
        'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
      ],
    }
  }
};