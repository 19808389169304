import { render, staticRenderFns } from "./OneColumnCentered.vue?vue&type=template&id=653e2881&scoped=true&"
import script from "./OneColumnCentered.vue?vue&type=script&lang=js&"
export * from "./OneColumnCentered.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "653e2881",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/mnt/c/Users/wbreu/Documents/code/personal/the-unit-planner/vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!module.hot.data) {
      api.createRecord('653e2881', component.options)
    } else {
      api.reload('653e2881', component.options)
    }
    module.hot.accept("./OneColumnCentered.vue?vue&type=template&id=653e2881&scoped=true&", function () {
      api.rerender('653e2881', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/OneColumnCentered.vue"
export default component.exports