
const defaultState = {
    authenticated: false,
    currentUserProfile: null,
    googleUser: null
};

const mutations = {
    authenticated (state, payload) {
        state.authenticated = payload;
    },
    googleUser (state, payload) {
        state.googleUser = payload;
    },
    currentUserProfile (state, payload) {
        state.currentUserProfile = payload;
    }
};

export default {
    state: defaultState,
    mutations,
    namespaced: true
};