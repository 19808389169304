var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "one-column-centered",
    [
      _c(
        "div",
        { staticClass: "d-flex flex-wrap mb-2" },
        [
          _c("h1", { staticClass: "mr-auto" }, [_vm._v("Your courses")]),
          _c(
            "b-button",
            {
              staticClass: "align-self-baseline",
              on: {
                click: function($event) {
                  return _vm.$refs.createCourseModal.show()
                }
              }
            },
            [_vm._v("New Course")]
          )
        ],
        1
      ),
      _c("create-course-modal", {
        ref: "createCourseModal",
        attrs: { id: "myModal" }
      }),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.courses2, function(myCourse) {
          return _c(
            "div",
            { key: myCourse.id, staticClass: "col-lg-4 col-md-6" },
            [
              _c(
                "b-card",
                { staticClass: "my-2" },
                [
                  _c(
                    "b-card-title",
                    [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "course",
                              params: { courseId: myCourse.id }
                            }
                          }
                        },
                        [_vm._v(_vm._s(myCourse.name))]
                      )
                    ],
                    1
                  ),
                  _c("b-card-sub-title", [
                    _vm._v(
                      "\n          " + _vm._s(myCourse.subtitle) + "\n        "
                    )
                  ]),
                  _c(
                    "b-list-group",
                    { attrs: { flush: "" } },
                    [
                      _vm._l(myCourse.recent_units, function(item) {
                        return _c(
                          "b-list-group-item",
                          { key: item.id },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "unit-plan",
                                    params: { id: item.id }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          ],
                          1
                        )
                      }),
                      _c("b-list-group-item", [_vm._v("placeholder...")])
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        0
      ),
      _c("h2", [_vm._v(" Calendar ")]),
      _c("img", {
        attrs: {
          src: "https://via.placeholder.com/728x300.png?text=Calendar?",
          alt: "placeholder"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }