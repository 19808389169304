<template>
  <div>
    <create-unit-plan-modal
      ref="createUnitPlanModal"
      id="myModal"
      :courseId="courseId"
    />
    <h3><router-link :to="{name: 'course', params: {courseId:courseId}}">{{courseName}}</router-link></h3>
    <b-button class="align-self-baseline" @click="$refs.createUnitPlanModal.show()">New Unit Plan</b-button>
    
    <ul>
    <li
      v-for="(unitPlan, idx) in unitPlans"
      :key="idx"
    > 
      <router-link :to="{name: 'unit-plan', params: {unitPlanId:unitPlan.id, courseId:courseId}}">{{ unitPlan.title }} ({{formatDate(unitPlan.start_date)}})</router-link>
    </li>
    </ul>
  </div>
</template>

<script>
// @ is an alias to /src
// import { db } from '../main'
// import firebase from 'firebase/app'
import moment from 'moment'
import CreateUnitPlanModal from "@/components/modals/CreateUnitPlanModal"
import _ from 'lodash'
export default {
  name: 'UnitPlanNavigation',
  components: {
    CreateUnitPlanModal,
  },
  props: ['courseId'],
  data () {
    return {
      thisCourseId: this.courseId || "none"
    }
  },
  methods: {
    formatDate: function (timestamp) {
      if (timestamp)
        return moment(timestamp).format("YYYY-MM-DD")
      else
        return "--"
    }
  },
  created() {
    this.$store.dispatch('unitPlans/openDBChannel', {courseId: this.courseId})
  },
  computed: {
    courseName: {
      get () { return _.get(this.$store.state.courses.data[this.thisCourseId], 'name', "") },
      set (value) { this.$store.dispatch('courses/patch', { id: this.thisCourseId, name: value }) }
    },
    unitPlans(){return  this.$store.state.unitPlans.data}
    
  },
  watch: {
    courseId: function (newValue) {
      this.thisCourseId = newValue
    }
  }
}
</script>
<style lang="css" scoped>
.md-card {
  max-width: 600px;
  margin: 4px;
  display: block;
  vertical-align: top;
}
</style>