import _ from "lodash";

/**
 * NOTE:
 * Make sure to do a _.deepCopy() of the value property that is passed in.
 * Don't just assign field_data to the this.value property. That will cause the field to not update initially.
 */


export const UpVuexComponentMixin = {
    props: ["field", "path"],
    computed: {

    },
    created: function () {
        // This debounces the update store method.
        this.updateStore = _.debounce(this._updateStore, 1000);
        // this.$on('asdf', newValue => {
        //     _.debounce(function() {this.updateStore(newValue)}, 1000)
        // })
    },
    methods: {
        // This needs debounced...
        _updateStore(data) {
            //this.$store.dispatch('courses/patch', {id: this.courseId, events: value}) }
            var patch = this.getPatchObj(data)
            if (!patch || !this.path) {
                console.log("Something isn't defined. Not updating.")
                return false
            }
            this.$emit('updateStore', patch)
            //this.$store.dispatch(`${this.vuexModule}/patch`, patch)
        },
        /*
        This creates an object using a namespace path. The purpose is to create deeply nested objects e.g.

        a.b.c becomes a: {b: c:{patch}}

        path: A dot separated path, or an array of keys.
        patch: An object
        separator: An alternative separator.
        */
        getPatchObj(obj, separator = ".") {
            var properties = Array.isArray(this.path) ? this.path : this.path.split(separator)
            var callback = function (key) {
                var o = {}
                if (properties.length) {
                    o[key] = callback(properties.shift())
                } else {
                    o[key] = obj
                }
                return o
            }
            let ret_val = properties.length ? callback(properties.shift()) : obj
            return ret_val
        }
    },
    watch: {
        field_data: function (newValue, oldValue) {
            console.log(this.value, newValue)
            if (_.isEqual(newValue, this.value)) {
                console.log("new value and old value the same")
            } else {
                this.updateStore(newValue)
            }
        },
        value: function (newValue) {
            this.field_data = _.cloneDeep(newValue)
        }
    }
}