var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row justify-content-center" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-10 border border-secondary p-5" },
      [
        _c("p", [
          _vm.saving > 0
            ? _c("span", [_vm._v("saving...")])
            : _c("span", [_vm._v("saved")])
        ]),
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("unit-plan-text-field", {
              staticClass: "h1 header-input mr-auto flex-grow-1",
              attrs: {
                placeholder: "Enter a title",
                path: "title",
                db_id: _vm.unitPlan.id
              },
              on: { updateStore: _vm.updateStore },
              model: {
                value: _vm.unitPlan.title,
                callback: function($$v) {
                  _vm.$set(_vm.unitPlan, "title", $$v)
                },
                expression: "unitPlan.title"
              }
            })
          ],
          1
        ),
        _c(
          "label",
          { staticClass: "label" },
          [
            _vm._v("Start Date \n    "),
            _c("unit-plan-date-picker", {
              attrs: { path: "start_date", db_id: _vm.unitPlan.id },
              on: { updateStore: _vm.updateStore },
              model: {
                value: _vm.unitPlan.start_date,
                callback: function($$v) {
                  _vm.$set(_vm.unitPlan, "start_date", $$v)
                },
                expression: "unitPlan.start_date"
              }
            })
          ],
          1
        ),
        _vm._l(_vm.sortAndReturnArray(_vm.unitPlan.sections), function(
          section
        ) {
          return _c("div", { key: section.id }, [
            _c("h2", { staticClass: "my-4" }, [_vm._v(_vm._s(section.title))]),
            _c(
              "div",
              { staticClass: "pl-5" },
              _vm._l(_vm.sortAndReturnArray(section.fields), function(field) {
                return _c(_vm.dynamicFieldComponent(field.type), {
                  key: field.id,
                  tag: "component",
                  attrs: {
                    field: field,
                    path:
                      "sections." +
                      section.id +
                      ".fields." +
                      field.id +
                      ".data",
                    vuexModule: "unitPlans",
                    db_id: _vm.unitPlan.id
                  },
                  on: { updateStore: _vm.updateStore },
                  model: {
                    value: field.data,
                    callback: function($$v) {
                      _vm.$set(field, "data", $$v)
                    },
                    expression: "field.data"
                  }
                })
              }),
              1
            ),
            _c("hr")
          ])
        }),
        _c(
          "b-tabs",
          { attrs: { card: "" } },
          [
            _vm._l(_vm.subunitPlans, function(subunitPlan) {
              return _c(
                "b-tab",
                { key: "dyn-tab-" + subunitPlan.id, attrs: { title: "Tab " } },
                [
                  _c("subunit-plan", {
                    attrs: {
                      subunitPlan: subunitPlan,
                      unitPlan: _vm.unitPlan,
                      path: "subunits." + subunitPlan.id
                    },
                    on: { updateStore: _vm.updateStore }
                  })
                ],
                1
              )
            }),
            _c(
              "template",
              { slot: "tabs" },
              [
                _c(
                  "b-nav-item",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.createSubunit($event)
                      }
                    }
                  },
                  [
                    _c("b", [_vm._v("+")]),
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Create a new subunit")
                    ])
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "text-center text-muted",
                attrs: { slot: "empty" },
                slot: "empty"
              },
              [
                _vm._v("\n        There are no open tabs"),
                _c("br"),
                _vm._v("\n        Open a new tab using the "),
                _c("b", [_vm._v("+")]),
                _vm._v(" button above.\n      ")
              ]
            )
          ],
          2
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }