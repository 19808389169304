<template>
  <one-column-centered>
    <b-jumbotron>
      <template slot="header">Unit Planner</template>

      <template slot="lead">
        Thank you for using Unit Planner. Please log in to continue.
      </template>

      <hr class="my-4" />

      <b-button
        variant="primary"
        style="color:white;"
        @click="login"
      >Log In with Google</b-button>
    </b-jumbotron>
  </one-column-centered>
</template>

<script>
/* global gapi */ // This is injected from an external script. This line mutes ESLint
import gapi_util from '../gapi_util'
import OneColumnCentered from "../layouts/OneColumnCentered"
export default {
  components: {
    OneColumnCentered
  },
  methods: {
    login: function () {
      var auth = gapi.auth2.getAuthInstance()
      auth.signIn().then(() => {
        gapi_util.updateAuthStatus().then(() => {
          this.$router.replace({ name: 'dashboard' })
        })
      })
    }
  }
}
</script>

<style scoped>
</style>

