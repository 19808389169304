<template>
  <b-modal
    ref="myModal"
    :title="'Create a New Unit Plan'"
    hide-footer
    lazy
  >
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        id="unitPlanNameGroup"
        label="Unit Plan Title:"
        label-for="unitPlanNameInput"
        description="Enter a title for your new unit plan."
      >
        <b-form-input
          id="unitPlanNameInput"
          type="text"
          v-model="unitPlan.title"
          required
          placeholder="Enter Title"
        />
      </b-form-group>

      <!-- <b-form-group
        id="unitPlanStandardGroup"
        label="Standard:"
        label-for="unitPlanStandardInput"
        description="Select a standards set."
      >
        <b-form-select
          id="unitPlanStandardInput"
          :options="standards"
          required
          v-model="unitPlan.standard"
        />
      </b-form-group> -->
      <div class="alert alert-danger" role="alert" id="responseError" v-if="responseError">{{responseError}}</div>
      <div class="d-flex">
        <b-button
          class="mr-auto"
          variant="danger"
          @click="$refs.myModal.hide()"
        >Cancel</b-button>
        <b-button
          type="submit"
          variant="primary"
          :disabled="busy"
        >
          <b-spinner
            small
            v-if="busy" class="mr-2"
          />
          <span
            class="sr-only"
            v-if="busy"
          >Loading...</span>Create</b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
export default {
  props: ["courseId"],
  data () {
    return {
      unitPlan: {
        title: "",
        standard: ""
      },
      standards: ["opt1", "opt2"],
      responseError: "",
      busy: false
    }
  },
  methods: {
    show () {
      this.$refs.myModal.show()
    },
    onSubmit () {
      this.busy = true;
      this.$store.dispatch('courses/createUnitPlan', {title: this.unitPlan.title, courseId: this.courseId})
      .then((response) => {
        this.busy = true;
        this.$refs.myModal.hide()
        this.$router.push({ name: 'unit-plan', params: {unitPlanId:response.docId, courseId:this.courseId}})
      }).catch(error => {
        this.responseError = error.message +": "+ error.details;
        this.busy = false;
      })
    }
  }
}
</script>

<style scoped>
</style>