const schema_version = 2

function courseSchema() {
    return {
        //owner populated by server
        version: schema_version,
        members: [],
        events: {},
        name: "",
    }
}

function unitPlanSchema() {
    return {
        "version": schema_version,
        "start_date": "",
        "blockout_dates": [],
        "title": "",
        "essential_questions": "",
        "summary": "",
        "sections": {
            "Focus Standards": {
                "order": 0,
                "title": "Focus Standards",
                "fields": {
                    "standards": {
                        "order": 0,
                        "label": "Standards",
                        "type": "standards_select",
                        "data": []
                    },
                    "Learning Targets": {
                        "order": 1.0,
                        "label": "Learning Targets",
                        "type": "text_array",
                        "data": []
                    }
                }
            },
            "Assessments": {
                "order": 1.0,
                "title": "Extended Assessments",
                "fields": {
                    "Assessments": {
                        "label": "Assessments",
                        "type": "text",
                        "data": "### Summative\n\n\n### Formative\n\n"
                    }
                }
            },
            "Learning Tasks": {
                "order": 2.0,
                "title": "Learning Tasks",
                "fields": {
                    "Learning Tasks": {
                        "label": "Learning Tasks",
                        "type": "text",
                        "data": ""
                    }
                }
            },
            "Accomodations and Interventions": {
                "order": 3.0,
                "title": "Accomodations and Interventions",
                "fields": {
                    "Accomodations and Interventions": {
                        "label": "Accomodations and Interventions",
                        "type": "text",
                        "data": ""
                    }
                }
            },
            "Attachments": {
                "order": 4.0,
                "title": "Attachments",
                "fields": {
                    "Attachments": {
                        "label": "Attachments",
                        "type": "text_array",
                        "data": {}
                    }
                }
            }
        },
        "subunits": {}
    }
}

function subunitSchema() {
    return {
        // references
        "version": schema_version,
        "order": 0,
        "sections": {
            "Focus Standards": {
                "order": 0,
                "title": "Focus Standards",
                "fields": {
                    "standards": {
                        "order": 0,
                        "label": "Standards",
                        "type": "standards_select",
                        "data": []
                    },
                    "Learning Targets": {
                        "order": 1.0,
                        "label": "Learning Targets",
                        "type": "text_array",
                        "data": []
                    }
                }
            }
        },
        "dailyPlans": {

        }
    }
}

function dailyPlanSchema() {
    return {
        "version": schema_version,
        "order": 0,
        "fields": {
            "Tasks": {
                "order": 0,
                "label": "Tasks",
                "type": "text",
                "data": "### Opener\n\n\n### Activities\n\n\n### Homework\n"
            },
            "Attachments": {
                "order": 1.0,
                "title": "Attachments",
                "fields": {
                    "Attachments": {
                        "label": "Attachments",
                        "type": "text_array",
                        "data": []
                    }
                }
            }
        }
    }
}
module.exports.unitPlanSchema = unitPlanSchema;
module.exports.courseSchema = courseSchema;
module.exports.subunitSchema = subunitSchema;
module.exports.dailyPlanSchema = dailyPlanSchema;