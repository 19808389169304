<template>
  <div class="container-fluid">
    <div class="row">
      <b-nav
        vertical
        class="col-12 col-md-3"
      >
        <slot name="left-nav">
          <b-nav-item active>Active</b-nav-item>
          <b-nav-item>Link</b-nav-item>
          <b-nav-item>Another Link</b-nav-item>
          <b-nav-item disabled>Disabled</b-nav-item>
        </slot>
      </b-nav>
      <div class="col-12 col-md-9">
          <div class="container">
        <slot name="main-content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
</style>