<template>
  <div>
      <h1>{{courseName}}</h1>
      <img
        src="https://via.placeholder.com/728x300.png?text=Project+Board"
        alt="placeholder"
      />
      <h2> Calendar </h2>
        <unit-plan-calendar :events='courseEvents' @addEvent='addEvent' @removeEvent='removeEvent' @updateEvent='updateEvent'></unit-plan-calendar>
  </div>
</template>

<script>
import LeftNavigationCenterContent from "../layouts/LeftNavigationCenterContent"
import UnitPlanCalendar from "../components/UnitPlanCalendar"
import uuidv4 from 'uuid/v4'
import _ from 'lodash'
export default {
  components: {
    LeftNavigationCenterContent,
    UnitPlanCalendar
  },
  data() {
      return {
          courseId: this.$route.params.courseId,
      }
  },
  computed: {
      courseName: {
          get(){return _.get(this.$store.state.courses.data[this.courseId], 'name', "") },
          set(value){ this.$store.dispatch('courses/patch', {id: this.courseId, name: value}) }
      },
      courseEvents: {
          get(){ return _.get(this.$store.state.courses.data[this.courseId], 'events', {}) },
          set(value){ this.$store.dispatch('courses/patch', {id: this.courseId, events: value}) }
      }
  },
  methods: {
    addEvent(event){
      var id = this.courseId
      this.$store.dispatch('courses/set', {id, events: {[event.id]: event}})
    },
    updateEvent(event){
      var id = this.courseId
      this.$store.dispatch('courses/set', {id, events: {[event.id]: event}})
    },
    removeEvent(event){
      return
    }
  },
//   beforeRouteEnter (to, from, next) {
//     // called before the route that renders this component is confirmed.
//     // does NOT have access to `this` component instance,
//     // because it has not been created yet when this guard is called!
//     next(vm => {vm.courseId = to.params.courseId})
//   },
  beforeRouteUpdate (to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    next()
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    next()
  }
}
</script>

<style scoped>
</style>