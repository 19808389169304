<template>
  <div>
    <h3>
      {{field.label}}
      </h3>
    <md-field>
      <md-select  v-model="field_data" name="standards" id="standards" multiple>
        <md-option v-for="standard in myStandards" :key="standard.id"      
        :value="standard.benchmark_number">
          {{ standard.benchmark_number }}
          <p style="white-space: normal; max-height:5em; overflow:hidden">{{ standard.description}}</p>
        </md-option>
      </md-select>
      <label for="standards">Select Standards</label>
    </md-field>
    <md-button @click="isCollapsed=!isCollapsed">{{isCollapsed ? "Expand": "Collapse"}}</md-button>
    <ul class="standards-list">
      <li :class="['standards-list-item', {collapsed: isCollapsed}]" v-for="standard in field_data" :key="standard.benchmark_number">
        <p class="md-title">{{ my_standards_dict.get(standard).benchmark_number }}</p>
        <span class="md-subheading">{{my_standards_dict.get(standard).idea_standard}}</span>
        <p :class="{collapsed: isCollapsed}"> {{my_standards_dict.get(standard).description}}</p>
      </li>
    </ul>
</div>
</template>

<script>
// import { db } from "../main";
import { standards_dict } from "../main";
import { standards } from "../main"
import { UpVuexComponentMixin } from "@/mixins/UpVuexComponentMixin"
import _ from "lodash";

export default {
  props: ['value', 'field', 'path'],
  mixins: [UpVuexComponentMixin],
  data(){
    return{
    newStandard: '',
    field_data: _.cloneDeep(this.value),
    isCollapsed: true
    }
  },
  methods: {
    addStandard: function() { 
      var value = this.newStandard
      if (!value) {
        return
      }
      this.field_data.push(value)
      this.newStandard = ""
    },
    autocompleteOpened: function() {
      //bug workaround.  see vue material #1656
      this.newStandard += ' '
      this.newStandard = this.newStandard.substring(0, this.newStandard.length-1);
    },
    standardFromRef: function(ref) {
      return standards.filter(standard => {return ref===standard.benchmark_number;});
    },
    
  },
  computed: {
    standardsFromRefs: function() {
      return this.field_data.map(reference => {return this.standardFromRef(reference)});
    },
    myStandards: function() {
      return standards
        .filter(standard => {
          return (
            standard.body_of_knowledge != "Reading Standards for Literacy in History/Social Studies 6–12"
          );
        })
        .filter(standard => {
          return standard.grade === "910";
        });
    },
    my_standards_dict: function(){
      return standards_dict;
    },
    myStandardsRefs: function() {
      return standards
        .filter(standard => {
          return (
            standard.body_of_knowledge != "Reading Standards for Literacy in History/Social Studies 6–12"
          );
        })
        .filter(standard => {
          return standard.grade === "910";
        }).map(standard => {return standard.benchmark_number});
    },
  },
};
</script>

<style>
.md-select-menu .md-menu-content-bottom-start .md-menu-content-small .md-menu-content {
  max-height: 50vh;
  max-width: 90%;
}
.autocomplete-result{
    white-space: normal; 
    max-height:5em; 
    overflow:hidden;
}
.standards-list-item {
  border-left: 5px solid #ff5252;
  list-style-type: none;
  margin: 10px;
  padding-left: 10px;
}
.standards-list {
  list-style-type: none;
}
p.collapsed {
  display: none;
}
li.collapsed {
  display:inline-block;
}
</style>