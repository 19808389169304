<template>
  <one-column-centered>
    <div class="d-flex flex-wrap mb-2">
    <h1 class ="mr-auto">Your courses</h1>
    <b-button class="align-self-baseline" @click="$refs.createCourseModal.show()">New Course</b-button>
    </div>
    <create-course-modal
      ref="createCourseModal"
      id="myModal"
    />
    <div class="row">
      <div
        class="col-lg-4 col-md-6"
        v-for="myCourse in courses2"
        :key="myCourse.id"
      >
        <b-card class="my-2">
          <b-card-title>
            <router-link :to="{name: 'course', params:{'courseId': myCourse.id}}">{{myCourse.name}}</router-link>
          </b-card-title>
          <b-card-sub-title>
            {{myCourse.subtitle}}
          </b-card-sub-title>
          <b-list-group flush>
            <b-list-group-item
              v-for="item in myCourse.recent_units"
              :key="item.id"
            >
              <router-link :to="{name:'unit-plan', params: {id:item.id}}">{{ item.name }}</router-link>
            </b-list-group-item>
            <b-list-group-item>placeholder...</b-list-group-item>
          </b-list-group>
        </b-card>
      </div>
    </div>
    <h2> Calendar </h2>
    <img
        src="https://via.placeholder.com/728x300.png?text=Calendar?"
        alt="placeholder"
      />
    <!-- <unit-plan-calendar></unit-plan-calendar> -->
  </one-column-centered>
</template>

<script>
// @ is an alias to /src
//import { db } from '../main'
import { createUnitPlan } from '../models/UnitPlan'
import UnitPlanCalendar from "../components/UnitPlanCalendar"
import OneColumnCentered from "../layouts/OneColumnCentered"
import { fFunctions } from "../main"
import CreateCourseModal from "@/components/modals/CreateCourseModal"
export default {
  name: 'dashboard',
  components: {
    OneColumnCentered,
    UnitPlanCalendar,
    CreateCourseModal
  },
  data () {
    return {
      events: [],
      showDate: new Date(),
      coursees: [
        {
          name: "English 10",
          id: "aweafwef",
          subtitle: "2019-2020",
          recent_units: [{
            name: "unit 1",
            id: "dfaneawf"
          },
          {
            name: "unit 2",
            id: "dfaneefwawf"
          },
          {
            name: "unit 3",
            id: "dfaneavzcbwf"
          },
          ]
        },
        {
          name: "English 10",
          id: "efwafea",
          subtitle: "2018-2019",
          recent_units: [{
            name: "unit 1",
            id: "dfanebtwawf"
          },
          {
            name: "unit 2",
            id: "dfanzxcbvxneawf"
          },
          {
            name: "unit 3",
            id: "dfanf2q3qeawf"
          },
          ]
        }, {
          name: "Rando course 3",
          id: "feawfab",
          subtitle: "2019-2020",
          recent_units: [{
            name: "unit 1",
            id: "dfaneaaegdfbwf"
          },
          {
            name: "unit 2",
            id: "dfanebfdat awf"
          },
          {
            name: "unit 3",
            id: "dfaneafewafdsvczwf"
          },
          ]
        }, {
          name: "World History",
          id: "fwea",
          subtitle: "3019-3020",
          recent_units: [{
            name: "unit 1",
            id: "dfaneafq34ffwf"
          },
          {
            name: "unit 2",
            id: "dfane15awf"
          },
          {
            name: "unit 3",
            id: "dfanea326wf"
          },
          ]
        }
      ]
    }
  },
  computed:{
    courses2(){return this.$store.state.courses.data}
  },
  methods: {
    newUnitPlan: function () {
      createUnitPlan(this.$store.state.auth.googleUser.uid).then(function (docRef) { this.$router.push('unit-plan/' + docRef.id) }.bind(this));
    },
    newCourse: function () {
      var newCourse = fFunctions.httpsCallable('createCourse')
      newCourse({ name: 'test1' }).then(function (result) {
        // Read result of the Cloud Function.
        console.log("success" + result)
        // ...
      }).catch(function (error) {
        // Getting the Error details.
        var code = error.code;
        var message = error.message;
        var details = error.details;
        console.log(code + " " + message + " " + details)
      });
    }

  },

  //   data() {
  //     return {
  //       standards: []
  //     }
  //   },
  //   firestore() {
  //     return {
  //       standards: db.collection('standards')
  //     }
  //   }
}
</script>
<style lang="css" scoped>
.md-card {
  max-width: 600px;
  margin: 4px;
  display: block;
  vertical-align: top;
}

</style>