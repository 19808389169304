import store from './store'
import full_config from './config'
import firebase from 'firebase/app'

const firebase_env = process.env.VUE_APP_FIREBASE_ENV
const config = full_config[firebase_env]
/* global gapi */
const functions = {
	initClient() {
		gapi.client.init({
			discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
			clientId: 'YOUR_CLIENT_ID',
			scope: 'https://www.googleapis.com/auth/drive.metadata.readonly'
		}).then(function () {
			// do stuff with loaded APIs
			console.log('it worked');
		});
	},
	initGoogleAPI() {
		return new Promise((resolve) => {
			var script = document.createElement('script');
			script.type = 'text/javascript';
			script.src = 'https://apis.google.com/js/api.js';
			script.onload = () => {
				gapi.load('client:auth2', () => {
					gapi.client.init({
						apiKey: config.google.api_key,
						clientId: config.google.client_id,
						discoveryDocs: config.google.discovery_docs,
						scope: config.google.scope,
					}).then(() => {
						store.commit('auth/currentUserProfile', null)
						store.commit('auth/authenticated', false)
						resolve()
					})
				});
			}
			document.getElementsByTagName('head')[0].appendChild(script);

		})
	},
	updateAuthStatus() {
		return new Promise((resolve) => {

			// we have no auth!
			if (!gapi.auth2.getAuthInstance()) {
				store.commit('auth/authenticated', false)
				resolve(false)
				return;
			}

			var googleUser = gapi.auth2.getAuthInstance().currentUser.get()
			//var googleProfile = googleUser.getBasicProfile()
			var isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get()

			if (isSignedIn) {

				var credential = firebase.auth.GoogleAuthProvider.credential(googleUser.getAuthResponse().id_token);
				// we now want to auth in to Firebase with the googleUser credentials
				// this function is a bit magic, it will create the user as well - if it does not exist
				firebase.auth().signInAndRetrieveDataWithCredential(credential).then((user) => {

					// do we have a user?
					var hasUser = Boolean(user !== null)

					if (hasUser) {
						// DB.ref('profiles').child(user.uid).once('value', (snap) => {
						// 	var profile = snap.val()
						// console.log(profile);
						// if (profile === null) {
						// 	var payload = {
						// 		email: user.email,
						// 		displayName: user.displayName,
						// 		photoURL: user.photoURL,
						// 		refreshToken: user.refreshToken,
						// 		uid: user.uid
						// 	}
						// 	snap.ref.update(payload).then((e) => {
						// 		store.commit('currentUserProfile', payload)
						// 		store.commit('authenticated', hasUser)
						// 		resolve()
						// 	})
						// } else {
						store.commit('auth/googleUser', user)
						store.commit('auth/authenticated', hasUser)
						resolve()
						//}
						// })
					} else {
						resolve(hasUser)
					}
				})
			} else {
				store.commit('auth/authenticated', false)
				resolve(false)
			}
		})
	}
}

export default functions