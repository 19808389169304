<template>
  <div>
    <h3>
      {{field.label}}
    </h3>
    <ul>
      <li
        v-for="(item, idx) in field_data"
        :key="idx"
      >
        <md-button
          class="md-icon-button md-body-1 remove"
          :md-ripple="false"
          aria-label="remove item"
          v-on:click="deleteItem(idx)"
        >
          X
        </md-button>
        <span class="align-span"><input v-model="field_data[idx]" /></span>
        <div class="clear"></div>
      </li>
      <li>
        <input
          class="new-item"
          v-model="newItem"
          v-on:keyup.enter="addItem"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { UpVuexComponentMixin } from "@/mixins/UpVuexComponentMixin"
import _ from "lodash";

export default {
  props: ['value'],
  mixins: [UpVuexComponentMixin],
  data () {
    return {
      field_data: Array.isArray(this.value) ? _.cloneDeep(this.value) : [],
      newItem: "",
    }
  },
  methods: {
    addItem: function () {
      if (!this.field_data) {
        this.field_data = []
      }
      this.field_data.push(this.newItem)
      this.newItem = ""
    },
    deleteItem: function (index) {
      this.field_data.splice(index, 1)
    }

  },
};
</script>

<style scoped>
input {
  width: 100%;
  height: 40px;
  margin-top: 15 px;
}
.new-item {
  padding-left: 52px;
}
.align-span {
  overflow: hidden;
  display: block;
  padding: 0 4px 0 10px;
}
.remove {
  float: left;
}
.clear {
  clear: both;
}
li {
  margin-top: 5px;
}
ul {
  padding: 0;
  margin: none;
  list-style-type: none;
}
</style>