<template>
<div class="container">
<button @click="print" class="no-print-but-display">Print</button>
<div id="printTarget" class="printLayout">
  <div class="page">
    <h1 class="md-display-2">{{unitPlan.name}}<br><span class="md-headline">{{ headline_date }}</span></h1>
    
    <h1>Focus Standards</h1>
    <ul>
      <li v-for="(standard, idx) in unitPlan.sections[0].fields[0].data" :key="idx">
        <strong>{{ standards_dict.get(standard).benchmark_number }}</strong> - {{ standards_dict.get(standard).description }} 
      </li>
    </ul>
    <h1>Learning Targets</h1>
    <ul>
      <li v-for="(target, idx) in unitPlan.sections[0].fields[1].data" :key="idx">
        {{ target }}
      </li>
    </ul>
    <h1>Assessments</h1>
    <p v-html="md(unitPlan.sections[1].fields[0].data)"> </p>

    <h1>Learning Tasks</h1>
    <p v-html="md(unitPlan.sections[2].fields[0].data)"> </p>
  </div>
  
  <div class="page" v-for="(weeklyPlan, idx) in unitPlan.weeklyPlans" :key="idx">
    <h1 class="md-display-2">Week {{idx+1}} <span class="md-subheading">{{ weekDate(idx).format("MMMM Do, YYYY") }}</span></h1>

    <h2>Focus Standards</h2>
    <ul>
      <li v-for="(item, idx) in weeklyPlan.sections[0].fields[0].data" :key="idx">{{item}}</li>
    </ul>
    <h2>Learning Targets</h2>
    <ul>
      <li v-for="(item, idx) in weeklyPlan.sections[1].fields[0].data" :key="idx">{{item}}</li>
    </ul>
    <div class="md-layout" v-for="(dailyPlan, idx3) in unitPlan.dailyPlans.slice(idx*5, idx*5+5)" :key="'daily'+idx3">
        <div class="md-layout-item md-layout md-large-size-20">
          <h2>
          <div class="md-layout-item md-large-size-100 md-medium-size-50">
            <span class="md-title">{{ dayDate(idx, idx3+1).format('dddd') }}</span>
          </div>
          <div class="md-layout-item md-large-size-100 md-medium-size-50">
            <span class="md-subheading">{{ dayDate(idx, idx3+1).format('MM/DD') }}</span>
          </div> 
          </h2>
        </div>      
        <div class="md-layout-item md-large-size-80">
          <p v-html="md(dailyPlan.fields[0].data)"> </p>
          <!-- <component v-for="(item, idx4) in dailyPlan.fields" :key="idx4" :is="dynamicFieldComponent(item.type)" :field="item"></component> -->
        </div>
        <div v-if="idx3 != 4" class="md-layout-item md-size-100">
          <hr>
        </div>
      </div>
  </div>
</div>
</div>
</template>

<script>

import moment from 'moment'
import { standards_dict } from "../main";
import { filterText } from '../models/TextFilter'
import { db } from "../main";

export default {
  name: 'UnitPlanPrintView',
  data() {
    return {
      standards_dict: standards_dict,
      unitPlan: {dailyPlans: [], weeklyPlans: []},
    }
  },
  firestore() {
    return {
      unitPlan: db.collection("unitPlans").doc(this.$route.params.id),
    };
  },
  methods: {
    md: function(value){
      return filterText(value)
    },
    dayDate: function (week, weekDay) {

      var dayDate = moment(this.unitPlan.start_date.toDate())
      dayDate.add({ days: weekDay - 1, weeks: week })
      return dayDate
    },
    formatDayDate: function (week, weekDay) {
      return this.dayDate(weekDay).format("dddd | M/D/YY")
    },
    weekDate: function (week) {
      var dayDate = moment(this.unitPlan.start_date.toDate())
      dayDate.add({ weeks: week })
      return dayDate
    },
    print: function(){
      window.print()
    }

  },
  computed: {
    headline_date: function(){
      return moment(this.unitPlan.start_date.toDate()).format("MMMM Do, YYYY")
    }
  },
  filters: {
    marked: function(value){
      return filterText(value)
    }
  }
}
</script>

<style lang="css" scoped>
@page {
  size: 8.5in 11in;
}


@media print {
.md-display-2 {
  page-break-before: always;
}

.no-print-but-display{
  display: none;
}
h1, h2, h3, h4, h5 {
  page-break-after: avoid;
}

}
  .container{
    max-width: 8.0in;
  }
  .md-card {
    max-width: 600px;
    margin: 4px;
    display: block;
    vertical-align: top;
  }
</style>