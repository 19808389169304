var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("left-navigation-center-content", {
    scopedSlots: _vm._u([
      {
        key: "left-nav",
        fn: function() {
          return [
            _c("UnitPlanNavigation", { attrs: { courseId: _vm.courseId } })
          ]
        },
        proxy: true
      },
      {
        key: "main-content",
        fn: function() {
          return [_c("router-view")]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }