<template>
  <left-navigation-center-content>
    <template v-slot:left-nav>
      <UnitPlanNavigation :courseId='courseId'></UnitPlanNavigation>
    </template>
    
    <template v-slot:main-content>
      <router-view></router-view>
    </template>
  </left-navigation-center-content>
</template>

<script>
import LeftNavigationCenterContent from "../layouts/LeftNavigationCenterContent"
import UnitPlanNavigation from '../components/UnitPlanNavigation'

export default {
  components: {
    LeftNavigationCenterContent,
    UnitPlanNavigation,
  },
  data() {
      return {
          courseId: this.$route.params.courseId,
      }
  },
  computed: {
      courseName: {
          get(){return _.get(this.$store.state.courses.data[this.courseId], 'name', "") },
          set(value){ this.$store.dispatch('courses/patch', {id: this.courseId, name: value}) }
      },
  },
  methods: {
    
  },
//   beforeRouteEnter (to, from, next) {
//     // called before the route that renders this component is confirmed.
//     // does NOT have access to `this` component instance,
//     // because it has not been created yet when this guard is called!
//     next(vm => {vm.courseId = to.params.courseId})
//   },
  beforeRouteUpdate (to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.
    next()
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    next()
  }
}
</script>

<style scoped>
</style>