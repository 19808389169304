import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth'
// import firebase from 'firebase/app'
import 'firebase/firestore'

import createEasyFirestore from 'vuex-easy-firestore'
import schemas from 'unit-planner-functions/Schemas'
import {
  createUnitPlan
} from '@/models/FirebaseFunctions'

const courseModule = {
  firestorePath: 'courses',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'courses',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    defaultValues: schemas.courseSchema(),
  },
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      start_date: '%convertTimestamp%',
    },
    addedHook: function (updateStore, doc) {
      replaceCourseEventTimestamps(doc);
      updateStore(doc)
    },
    modifiedHook: function (updateStore, doc) {
      replaceCourseEventTimestamps(doc);
      updateStore(doc)
    },
  },
  // you can also add your own state/getters/mutations/actions
  actions: {
    createUnitPlan(context, unitPlan) {
      return new Promise((resolve, reject) => {
        createUnitPlan({
            title: unitPlan.title,
            courseId: unitPlan.courseId
          })
          .then(result => {
            console.log('success')
            resolve(result.data)
          })
          .catch(err => {
            console.log("fail")
            reject(err)
          })

      })
    }
  }
}

function replaceCourseEventTimestamps(doc) {
  // for (let key in doc.events){
  //   doc.events[key].startDate = doc.events[key].startDate.toDate()
  //   doc.events[key].endDate = doc.events[key].endDate.toDate()
  // }
  // Lets use string dates instead
  return doc;
}

const unitPlanModule = {
  firestorePath: 'courses/{courseId}/unitPlans',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'unitPlans',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    defaultValues: schemas.unitPlanSchema(),
  },
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      start_date: '%convertTimestamp%',
    },
    // addedHook: function (updateStore, doc) { replaceCourseEventTimestamps(doc); updateStore(doc) },
    // modifiedHook: function (updateStore, doc) { replaceCourseEventTimestamps(doc); updateStore(doc) },
  }
  // you can also add your own state/getters/mutations/actions
}

const easyFirestore = createEasyFirestore([courseModule, unitPlanModule], {
  logging: true
})
Vue.use(Vuex)

const modules = {
  auth: authModule
}

// root state object.
// each Vuex instance is just a single state tree.
const state = {
  user: null
}

// mutations are operations that actually mutates the state.
// each mutation handler gets the entire state tree as the
// first argument, followed by additional payload arguments.
// mutations must be synchronous and can be recorded by plugins
// for debugging purposes.
const mutations = {
  // userUpdated (state, user) {
  //   state.user = user;
  // }
}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {
  // increment: ({ commit }) => commit('increment'),
}

// getters are functions
const getters = {
  // isLoggedIn: state => state.user != null ? true : false,
  // user: state => state.user
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules,
  plugins: [easyFirestore]
})


//
/*
for documents, store listeners in a hashmap/object when we need them (like unit plans, or unit plan queries) and subscribe to them. store the unsubscribe in the same place. https://firebase.google.com/docs/firestore/query-data/listen

Then when we get updates, commit changes to the store: https://github.com/tcstx/vue-vuex-firebase-tutorial/blob/master/src/store.js



*/