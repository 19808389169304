var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "md-field",
        [
          _c(
            "md-select",
            {
              attrs: { name: "week_targets", id: "week_targets", multiple: "" },
              model: {
                value: _vm.field_data,
                callback: function($$v) {
                  _vm.field_data = $$v
                },
                expression: "field_data"
              }
            },
            _vm._l(_vm.source, function(item, idx) {
              return _c("md-option", { key: idx, attrs: { value: item } }, [
                _vm._v(_vm._s(item))
              ])
            }),
            1
          )
        ],
        1
      ),
      _c(
        "ul",
        _vm._l(_vm.field_data, function(item, idx) {
          return _c("li", { key: idx }, [
            _vm._v("\n      " + _vm._s(item) + "\n    ")
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }